<template>
  <div class="download-container mt-4">
    <h3>PAM SPFX</h3>
    <div>
      {{ $t("downloads.introText") }}
    </div>
    <p>
      <a v-if="Constants.INSTANCE === 'IOZ'"
        href="https://iozspfxcdn.blob.core.windows.net/releases/pam/latest/ioz-pa-monitoring-spfx.sppkg" target="_blank"
        class="btn btn-pam ms-2 m-3 mb-3">Download Latest</a>
      <a v-if="Constants.INSTANCE === 'Prodware'"
        href="https://iozspfxcdn.blob.core.windows.net/releases/pam-prodware/latest/prodware-pa-monitoring-spfx.sppkg"
        target="_blank" class="btn btn-pam ms-2 m-3 mb-3">Download Latest</a>
      <a v-if="Constants.INSTANCE === 'Castelli'"
        href="https://iozspfxcdn.blob.core.windows.net/releases/pam-castelli/latest/castelli-pa-monitoring-spfx.sppkg"
        target="_blank" class="btn btn-pam ms-2 m-3 mb-3">Download Latest</a>
      <a v-if="Constants.INSTANCE === 'Dinotronic'"
        href="https://iozspfxcdn.blob.core.windows.net/releases/pam-dinotronic/latest/dinotronic-pa-monitoring-spfx.sppkg"
        target="_blank" class="btn btn-pam ms-2 m-3 mb-3">Download Latest</a>
    </p>
    <p>
      {{ $t("downloads.configurationDataText") }}
    <table class="infoTable">
      <tr>
        <td class="fw-bold">API-Key:</td>
        <td>{{ apiKey }}</td>
      </tr>
      <tr>
        <td class="fw-bold">Customer-ID:</td>
        <td>{{ customerId }}</td>
      </tr>
    </table>
    </p>
    <p>
      {{ $t("downloads.configurationHelpText") }}
      <img src="../assets/ScreenshotSpfxSettings.png" class="d-block p-4 align-top"
        alt="Power Automate Monitor SPFX Settings Screenshot" />
    </p>
  </div>
</template>

<script>
import { Constants } from '../shared/constants';

export default {
  name: 'DownloadSpfx',
  data() {
    return { Constants };
  },
  computed: {
    customerId() {
      return this.$store.getters.customerId;
    },
    apiKey() {
      return this.$store.getters.apiKey;
    },
  },
};
</script>

<style lang="scss" scoped>
.infoTable {
  width: 33%;
}
</style>
