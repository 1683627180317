<template>
  <div v-if="dashboardData">
    <div class="row">
      <div class="col-md-8 col-sm-12 mb-4 mb-md-0">
        <h3>{{ $t("general.dashboard") }}
          <a target="_blank" class="ms-2" :href="getAdminCenterLink()">
            <font-awesome-icon v-title="$t('general.adminCenterLink')" icon="toolbox" class="fa-small" />
          </a>
        </h3>
        <small>{{ environmentName }}</small>
      </div>
      <div class="col-md-2 col-sm-12 mb-4 mb-md-0" v-if="environmentAnalytics">
        <div class="mb-1"><span class="fw-bold text-end analyticsNumber">{{ environmentAnalytics.flowCount }}</span> {{
          $t("general.workflows")
        }}</div>
        <div><span class="fw-bold text-end analyticsNumber">{{ environmentAnalytics.flowsStartedCount }}</span> {{
          $t("general.activeWorkflows")
        }}</div>
        <div><span class="fw-bold text-end analyticsNumber">{{ environmentAnalytics.flowsSuspendedCount }}</span> {{
          $t("general.pausedWorkflows")
        }}</div>
        <div><span class="fw-bold text-end analyticsNumber">{{ environmentAnalytics.flowsStoppedCount }}</span> {{
          $t("general.inactiveWorkflows")
        }}</div>
        <div v-title="$t('general.longRunningWorkflowsHint')" title-placement="bottom">
          <span class="fw-bold text-end analyticsNumber">
            {{ environmentAnalytics.longRunningFlowsCount }}
          </span>
          {{ $t("general.longRunningWorkflows") }}
          <font-awesome-icon icon="info-circle" class="fa-small" />
        </div>
        <hr>
        <div v-title="$t('general.unsharedWorkflowsHint')" title-placement="bottom"><span
            class="fw-bold text-end analyticsNumber">
            {{ environmentAnalytics.flowsUnsharedCount }}</span>
          {{ $t("general.unsharedWorkflows") }}
          <font-awesome-icon icon="info-circle" class="fa-small" />
        </div>
      </div>
      <div class="col-md-2 col-sm-12 text-center mb-4 mb-md-0">
        <small class="fw-bold">{{ $t("dashboard.succesfulLastRuns") }}</small>
        <template>
          <vue-gauge :key="dashboardData.successfulRunPercentage" :refid="'flow-run-gauge'"
            :options="flowRunGaugeOptions"></vue-gauge>
        </template>
      </div>
    </div>

    <h5>
      {{ $t("general.lastRuns") }}
      <span class="refreshIcon ms-2" @click="refreshDashboard()" v-title="$t('general.update')">
        <font-awesome-icon icon="sync-alt" class="fa-small" :class="{ 'fa-spin': refreshInProgress }" />
      </span>
    </h5>
    <div class="form-check form-switch smallerText">
      <input class="form-check-input" type="checkbox" id="onlyFailedRunsSwitched" @click="runSwitchClicked" />
      <label class="form-check-label" for="onlyFailedRunsSwitched" @click="runSwitchClicked">
        {{ $t("dashboard.onlyFailedRuns") }}
      </label>
    </div>
    <div id="lastRunContainer" class="table-responsive" v-if="dashboardData.lastRuns && flows">
      <el-table lazy :data="dashboardData.lastRuns" :default-sort="{ prop: 'startedOn', order: 'descending' }"
        :row-class-name="rowClassName" style="width: 100%">
        <div slot="empty">{{ $t("notifications.noFlowRunsFound") }}</div>
        <el-table-column width="40" label="✔" v-if="errorHandledEnabled">
          <template slot-scope="scope">
            <input type="checkbox"
              v-if="scope.row.status === Constants.FLOW_RUN_FAILED || scope.row.status === Constants.FLOW_RUN_CANCELLED"
              :checked="scope.row.errorHandled ? true : false"
              @change="errorHandledChanged($event, scope.row.id, scope.row.flowId, scope.row.environmentId)" />
          </template>
        </el-table-column>
        <el-table-column prop="flowRun.flowId" label="Flow">
          <template slot-scope="scope">
            <span class="cursor-pointer" @click="onFlowSelected(scope.row.flowId)">{{
              getFlowName(scope.row.flowId)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="endedOn" :label="$t('general.endedOn')" width="180" sortable>
          <template slot-scope="scope">
            {{ scope.row.endedOn | dateTime }}
          </template>
        </el-table-column>
        <el-table-column prop="duration" :label="$t('general.duration')" width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.status === Constants.FLOW_RUN_RUNNING">{{ $t('general.running') }}</span>
            <span v-else>{{ scope.row.duration | duration }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="status" :label="$t('general.result')" width="120">
          <template slot-scope="scope">
            <div v-if="scope.row.status === Constants.FLOW_RUN_SUCCEEDED" class="ps-3">
              <span class="resultIcon success">
                <font-awesome-icon icon="check-circle" />
              </span>
            </div>
            <div v-else-if="scope.row.status === Constants.FLOW_RUN_RUNNING" class="ps-3">
              <span class="resultIcon running">
                <font-awesome-icon icon="angle-double-right" />
              </span>
            </div>
            <div v-else class="ps-3">
              <span class="resultIcon failed" v-title="
                scope.row.remediation
                  ? scope.row.remediation.errorSubject
                  : $t('notifications.reasonUnknown')
              " title-placement="top-end">
                <font-awesome-icon icon="times-circle" />
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('general.link')" width="80">
          <template slot-scope="scope">
            <a target="_blank" class="copyLink ms-1" :href="getFlowRunLink(scope.row.flowId, scope.row.id)">
              <font-awesome-icon icon="external-link-alt" />
            </a>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import VueGauge from 'vue-gauge';
import { GET_DASHBOARD_REQUEST } from '../store/actions/dashboard';
import { UPDATE_FLOW_RUN_ERRORHANDLED_REQUEST } from '../store/actions/flow';
import { Constants } from '../shared/constants';
import { GET_ENVIRONMENT_FLOW_RUNS_REQUEST } from '../store/actions/customer';

export default {
  name: 'Dashboard',
  components: { VueGauge },
  data() {
    return {
      refreshInProgress: '',
      Constants,
      onlyFailedRuns: false,
    };
  },
  created() {
    this.$store.dispatch(GET_DASHBOARD_REQUEST,
      {
        customerId: this.$store.getters.customerId,
        environmentId: this.$route.params.envId,
      });

    this.$store.dispatch(GET_ENVIRONMENT_FLOW_RUNS_REQUEST,
      {
        customerId: this.$store.getters.customerId,
        environmentId: this.$route.params.envId,
      });
  },
  computed: {
    errorHandledEnabled() {
      return this.$store.getters.customerSettings?.useErrorTreatedOption;
    },
    environmentName() {
      const array = this.$store.state.customer.environments;
      if (array == null) return array;

      return array.filter((env) => env.id === this.$route.params.envId)[0].displayName;
    },
    environmentAnalytics() {
      if (!this.environmentFlowRuns || !this.flows) return {};

      const flowCount = this.flows.length;
      const flowsStartedCount = this.flows.filter((x) => x.state === Constants.FLOW_STATE_STARTED).length;
      const flowsStoppedCount = this.flows.filter((x) => x.state === Constants.FLOW_STATE_STOPPED).length;
      const flowsSuspendedCount = this.flows.filter((x) => x.state === Constants.FLOW_STATE_SUSPENDED).length;
      const flowsUnsharedCount = this.flows.filter((x) => x.owners <= 1).length;

      const today = new Date();
      const threshold = new Date(today.setDate(today.getDate() - 20));
      const longRunningFlowsCount = this.environmentFlowRuns.filter((x) => x.status === Constants.FLOW_RUN_RUNNING && new Date(x.startedOn) < threshold).length;

      return {
        flowCount,
        flowsStartedCount,
        flowsStoppedCount,
        flowsSuspendedCount,
        flowsUnsharedCount,
        longRunningFlowsCount,
      };
    },
    flows() {
      return this.$store.state.flow.flows;
    },
    environmentFlowRuns() {
      return this.$store.state.customer.environmentFlowRuns;
    },
    dashboardData() {
      return this.$store.state.dashboard.data;
    },
    flowRunGaugeOptions() {
      let arcDelimiter = this.dashboardData.successfulRunPercentage;
      if (arcDelimiter <= 0) arcDelimiter = 0.001;
      if (arcDelimiter >= 100) arcDelimiter = 99.999;

      return {
        needleValue: this.dashboardData.successfulRunPercentage,
        arcDelimiters: [arcDelimiter],
        centralLabel: `${this.dashboardData.successfulRunPercentage} %`,
        arcColors: ['rgba(0, 128, 0, 0.5)', 'rgba(139, 0, 0, 0.5)'],
        rangeLabel: ['0 %', '100 %'],
        chartWidth: '200',
        needleColor: '#747474',
        labelsFont: '"Saira Semi Condensed", sans-serif',
      };
    },
  },
  methods: {
    rowClassName({ row }) {
      // eslint-disable-next-line no-nested-ternary
      return row.status === Constants.FLOW_RUN_SUCCEEDED ? 'success' : (row.status === Constants.FLOW_RUN_RUNNING ? 'running' : 'failed');
    },
    onFlowSelected(flowId) {
      this.$router.push({ name: 'ListFlowRuns', params: { envId: this.$route.params.envId, flowId } });
    },
    getFlowName(flowId) {
      const flow = this.flows.filter((f) => f.id === flowId)[0];
      return flow ? flow.name : this.$t('general.unkown');
    },
    getFlowCount() {
      return this.$store.state.flow.flows ? this.$store.state.flow.flows.length : 0;
    },
    getFlowRunLink(flowId, flowRunId) {
      const { environments } = this.$store.state.customer;
      if (!environments) return environments;

      const environment = environments.filter((env) => env.id === this.$route.params.envId)[0];
      // eslint-disable-next-line max-len
      return `https://flow.microsoft.com/manage/environments/${environment.name}/flows/${flowId}/runs/${flowRunId}`;
    },
    getAdminCenterLink() {
      const { environments } = this.$store.state.customer;
      if (!environments) return environments;
      const environment = environments.filter((env) => env.id === this.$route.params.envId)[0];
      return `https://admin.powerplatform.microsoft.com/environments/${environment.name}/flows`;
    },
    runSwitchClicked() {
      this.onlyFailedRuns = !this.onlyFailedRuns;
      this.refreshDashboard();
    },
    errorHandledChanged(event, flowRunId, flowId, environmentId) {
      this.$store.dispatch(UPDATE_FLOW_RUN_ERRORHANDLED_REQUEST,
        {
          customerId: this.$store.getters.customerId,
          environmentId,
          flowId,
          flowRunId,
          handled: event.target.checked,
        })
        .then(() => {
          this.refreshDashboard(true);
        })
        .catch(() => {
          this.$toasted.error(this.$t('notifications.genericError'), { duration: 2400 });
        });
    },
    refreshDashboard(dontShowHint) {
      if (!this.refreshInProgress) {
        this.refreshInProgress = true;

        this.$store.dispatch(GET_DASHBOARD_REQUEST,
          {
            customerId: this.$store.getters.customerId,
            environmentId: this.$route.params.envId,
            onlyFailedRuns: this.onlyFailedRuns,
          })
          .then(() => {
            if (!dontShowHint) this.$toasted.success(this.$t('notifications.updated'), { duration: 1200 });
          })
          .catch(() => {
            this.$toasted.error(this.$t('notifications.genericError'), { duration: 2400 });
          })
          .finally(() => {
            this.refreshInProgress = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  color: inherit;
}

.ps {
  height: calc(100vh - 340px);
}

#lastRunContainer {
  tr {
    .cursor-pointer {
      cursor: pointer;
    }

    &.success {
      background-color: rgba($color: #46a218, $alpha: 0.1);
    }

    &.running {
      background-color: rgba($color: #116676, $alpha: 0.1);
    }

    &.failed {
      background-color: rgba($color: #761133, $alpha: 0.1);
    }
  }
}

.analyticsNumber {
  width: 35px;
  display: inline-block;
  padding-right: 8px;
}
</style>
