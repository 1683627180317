<template>
  <div id="app">
    <send-feedback-modal />

    <header v-if="isAuthenticated">
      <nav class="navbar navbar-expand-lg navbar-dark p-3 shadow">
        <router-link :to="{ name: 'Dashboard', params: { envId: this.environmentId } }">
          <img src="./assets/logo.png" height="60px" class="d-inline-block align-top"
            alt="Power Automate Monitor Logo" />
        </router-link>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
          aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav me-auto">
            <li class="nav-item">
              <router-link :to="{ name: 'Dashboard', params: { envId: this.environmentId } }" class="nav-link" exact>{{
                $t("navigation.home")
              }}</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'Alerts', params: { envId: this.environmentId } }" class="nav-link" exact>{{
                $t("navigation.alerts")
              }}</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'Settings' }" class="nav-link" exact>{{
                $t("navigation.settings")
              }}</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'Downloads' }" class="nav-link" exact>{{
                $t("navigation.downloads")
              }}</router-link>
            </li>
          </ul>
          <div class="navbar-text me-4">Tenant: {{ this.tenantId }}</div>
          <div class="d-block-xs d-inline-md navbar-text me-2 selectLabel" v-if="environments && routeHasEnvParam">
            {{ $t("general.environment") }}
          </div>
          <span class="navbar-text me-4" v-if="environments && routeHasEnvParam">
            <select name="EnvironmentSelect" title="EnvironmentSelect" class="form-select d-md-inline mt-2 mt-md-0"
              @change="onEnviromentChange($event)" v-model="environmentId">
              <option v-for="environment in environments" :key="environment.id" :value="environment.id">
                {{ environment.displayName }}
              </option>
            </select>
          </span>
          <span class="navbar-text">
            <language-switcher />
            <div id="supportlink" class="pt-3 pt-md-0 d-md-inline link-hover me-2">
              <a v-if="Constants.INSTANCE === 'IOZ'" href="https://www.ioz.ch/kontakt/" target="_blank">{{
                $t("general.needHelp")
              }}</a>
              <a v-if="Constants.INSTANCE === 'Prodware'" href="https://www.prodwaregroup.com/nl-nl/contact/"
                target="_blank">{{ $t("general.needHelp") }}</a>
              <a v-if="Constants.INSTANCE === 'Castelli'" href="https://castelli-solutions.ch/contact/"
                target="_blank">{{ $t("general.needHelp") }}</a>
              <a v-if="Constants.INSTANCE === 'Dinotronic'" href="https://www.dinotronic.ch/kontakt/" target="_blank">{{
                $t("general.needHelp")
              }}</a>
            </div>
            <div id="feedbackDialog" class="pt-3 pt-md-0 d-md-inline link-hover me-2" @click="openFeedbackModal()">{{
              $t("general.makeSuggestion")
            }}</div>
            <div id="logout" class="pt-3 pt-md-0 d-md-inline link-hover" @click="onLogoutClicked()">{{
              $t("general.logout")
            }}</div>
          </span>
        </div>
      </nav>
    </header>
    <div class="container-fluid">
      <router-view />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import LanguageSwitcher from './components/LanguageSwitcher.vue';
import SendFeedbackModal from './components/SendFeedback.vue';
import { GET_ENVIRONMENTS_REQUEST } from './store/actions/customer';
import { AUTH_LOGOUT } from './store/actions/auth';
import { GET_FLOWS_REQUEST, CLEAR_FLOWS_REQUEST } from './store/actions/flow';
import { GET_FAVORITES_REQUEST } from './store/actions/favorite';
import {
  CLEAR_DASHBOARD_REQUEST,
  GET_DASHBOARD_REQUEST,
} from './store/actions/dashboard';
import { Constants } from './shared/constants';

export default {
  name: 'App',
  components: {
    LanguageSwitcher,
    SendFeedbackModal,
  },
  data() {
    return { Constants };
  },
  created() {
    axios.defaults.headers.common.ApiKey = this.$store.getters.apiKey;
  },
  mounted() {
    if (this.$store.getters.isAuthenticated) {
      this.$store.dispatch(
        GET_ENVIRONMENTS_REQUEST,
        this.$store.getters.customerId,
      ).then((data) => {
        this.checkForSingleEnvironment(data);
      });
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'authStatus', 'tenantId']),
    tenantId() {
      return this.$store.getters.tenantId;
    },
    environments() {
      const array = this.$store.state.customer.environments;
      if (array == null) return array;

      const sorted = array
        .slice()
        // eslint-disable-next-line no-nested-ternary
        .sort((a, b) => (a.displayName > b.displayName ? 1 : a.displayName < b.displayName ? -1 : 0));
      return sorted;
    },
    environmentId: {
      get() {
        return this.$route.params.envId;
      },
      set(updatedValue) {
        return updatedValue;
      },
    },
    routeHasEnvParam() {
      return !!this.$route.params.envId;
    },
  },
  methods: {
    onEnviromentChange(event) {
      if (event.target.value !== '') {
        this.$store.dispatch(CLEAR_FLOWS_REQUEST);
        this.$store.dispatch(GET_FLOWS_REQUEST, {
          customerId: this.$store.getters.customerId,
          environmentId: event.target.value,
        }).then(() => {
          this.$store.dispatch(GET_FAVORITES_REQUEST, this.$store.getters.customerId).catch(() => {
            this.$toasted.error(this.$t('notifications.loadFavoritesError'), { duration: 2400 });
          });
        });
        this.$store.dispatch(CLEAR_DASHBOARD_REQUEST);
        this.$store.dispatch(GET_DASHBOARD_REQUEST, {
          customerId: this.$store.getters.customerId,
          environmentId: event.target.value,
        })
          .then(() => {
            this.$router.push({ name: 'Dashboard', params: { envId: event.target.value } });
          });
      }
    },
    onLogoutClicked() {
      this.$store.dispatch(AUTH_LOGOUT).then(() => {
        this.$router.push('/login');
      });
    },
    openFeedbackModal() {
      this.$modal.show('send-feedback');
    },
    feedbackModalClosed() {
      this.$modal.hide('send-feedback');
    },
    checkForSingleEnvironment(data) {
      if (data.length === 1) {
        this.$store.dispatch(GET_FLOWS_REQUEST, {
          customerId: this.$store.getters.customerId,
          environmentId: data[0].id,
        });
        this.$store.dispatch(GET_DASHBOARD_REQUEST, {
          customerId: this.$store.getters.customerId,
          environmentId: data[0].id,
        });
      }
    },
  },
};
</script>

<style lang="scss">
html,
body {
  font-family: 'Saira Semi Condensed', sans-serif !important;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;

  a {
    text-decoration: none !important;
  }
}

#logout {
  &:hover {
    color: white;
  }
}

a.btn-pam {
  background-color: #a21846;
  color: white;

  &:hover {
    color: white;
    background-color: #591195;
  }
}

.navbar {
  background-color: #a21846;

  .nav-item>.nav-link.router-link-exact-active,
  .nav-item>.nav-link.router-link-active {
    color: white !important;
  }

  .navbar-text {
    color: white !important;
  }
}

.fa-small {
  font-size: 0.8em;
}

.refreshIcon,
.clickable {
  cursor: pointer;
}

.bg-purple {
  background-color: #591195 !important;
  color: white !important;
}

.link-hover {
  padding: 0 4px;
  cursor: pointer;

  &:hover {
    background-color: rgba($color: #FFF, $alpha: 0.5);
  }
}

.text-purple {
  color: #591195 !important;
}

.form-check .form-check-input:checked {
  background-color: #a21846;
  border-color: #a21846;
}
</style>
